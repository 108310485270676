import { Box, Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import 'react-light-accordion/demo/css/index.css';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import HeaderHeroImg from '../Assets/images/shortribfilet.jpg';
import Footer from '../Components/Footer/Footer';
import SocialNewsLetter from '../Components/Footer/SocialNewsLetter';
import NavbarPage from '../Components/Header/NavbarPage';

function NevadaLegal() {
  const history = useHistory();
  const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const formik = useFormik({
    initialValues: {
      fname: '',
      lname: '',
      email: '',
      phone: '',
    },
    validationSchema: yup.object({
      fname: yup.string().trim().required('First name is required.'),
      lname: yup.string().trim().required('Last name is required.'),
      email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
      phone: yup.string().matches(phoneRegExp, 'Phone number is not valid.'),
    }),
    onSubmit: (v) => {
      const data = {
        firstName: v.fname,
        lastName: v.lname,
        emailAddress: v.email,
        phoneNumber: v.phone,
        notes: '',
        residentState: 'NV',
      };
      fetch('/send-nevada-lms', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          const parsedRes = JSON.parse(res);
          // on database insert we will display errorDisplayMessage otherwise errorMessage
          // errorMessage is null on success whereas errorMessage on failure is more descriptive
          const message = parsedRes.errorMessage || parsedRes.errorDisplayMessage;
          history.push({
            pathname: '/nevada-opt-out-submit',
            state: { detail: message },
          });
        });
    },
  });

  return (
    <div className="nevada-legal-page-content">
      <NavbarPage transparent="transparent" />
      <section
        className="brinker-header-module chilis hero"
        style={{
          backgroundImage: `url(${HeaderHeroImg})`,
        }}
      >
        <div className="overlay" />
        <div className="hero-text">
          <div className="grid special">
            <h1 className="header">NEVADA DATA PRIVACY STATUTE</h1>
          </div>
        </div>
      </section>

      <div className="brinker-wrapper-module mt-5 mb-5">
        <div className="brinker-legal-module" id="NevadaSB2020-Module">
          <div className="terms container">
            <div className="row nevada-marg">
              <p style={{ color: '#EE2D24', fontSize: '21px' }}>
                <strong>
                  Right to Opt Out of Sale of Personal Information for Nevada
                  consumers
                </strong>
              </p>
            </div>

            <div className="row nevada-marg">
              Brinker does not sell your personal information. But we do follow
              the law! And Nevada law says that we need to give a Nevada
              consumer a method to submit a request to us not to sell his or her
              personal information, whether collected previously or in the
              future. So please feel free to complete the form below. Please
              note that we are making this available to all of Brinker’s U.S.
              consumers.
            </div>
            <div className="row nevada-marg">
              <Box
                onSubmit={formik.handleSubmit}
                component="form"
                sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}
              >
                <div>
                  <TextField
                    id="fname"
                    name="fname"
                    label="First Name"
                    value={formik.values.fname}
                    onChange={formik.handleChange}
                    error={formik.touched.fname && Boolean(formik.errors.fname)}
                    helperText={formik.touched.fname && formik.errors.fname}
                    variant="filled"
                    required
                  />
                  <TextField
                    id="lname"
                    name="lname"
                    label="Last Name"
                    value={formik.values.lname}
                    onChange={formik.handleChange}
                    error={formik.touched.lname && Boolean(formik.errors.lname)}
                    helperText={formik.touched.lname && formik.errors.lname}
                    variant="filled"
                    required
                  />
                </div>
                <div>
                  <TextField
                    id="phone"
                    name="phone"
                    label="Phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                    variant="filled"
                  />
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    variant="filled"
                    required
                  />
                </div>
                <div>
                  <Button type="submit" className="m-3" variant="contained">
                    Submit
                  </Button>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <SocialNewsLetter />
      <Footer />
    </div>
  );
}

export default NevadaLegal;
