import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App/App';
import './App/pages/Company/Company-Default/Company.css';
import './App/pages/Components/Footer/Footer.css';
import './App/pages/Components/Header/NavbarPage.css';
import './App/pages/Media/MediaRoom.css';
import './index.css';

render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);
