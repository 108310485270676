import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function RedirectorPreview() {
  const history = useHistory();
  useEffect(() => {
    const abortController = new AbortController();

    fetch(`/brinkercom${window.location.pathname}${window.location.search}`, {
      signal: abortController.signal,
    })
      .then((res) => res.json())
      .then((data) => {
        history.push(data.url);
      });
  }, [history]);
  return false;
}

export default RedirectorPreview;
