import linkResolver from 'prismic-configuration';
import { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'prismic-reactjs';
import key from 'weak-key';

function NavbarPage(props) {
  const [items, setItems] = useState([]);
  const [brand, setBrand] = useState([]);
  const [header] = useState(() => {
    const { header: headerProp } = props;
    return headerProp;
  });
  const [transparent] = useState(() => {
    const { transparent: transparentProp } = props;
    return transparentProp;
  });
  useEffect(() => {
    const abortController = new AbortController();
    const getPrismicData = () => {
      fetch('/brinkercom/header/header', {
        signal: abortController.signal,
      })
        .then((res) => res.json())
        .then((data) => {
          const tItems = [];
          const tBrand = [];
          const { document: { data: { body: navBarData } } } = data;
          if (header === 'normal') {
            navBarData.forEach((slice) => {
              const {
                items: lItems,
                slice_type: sliceType, primary: {
                  navigation_type: navigationType,
                  navigation_link: navigationLink,
                  navigation_text: navigationText,
                  navigation_image: {
                    url,
                    alt,
                    mobile: { url: mUrl },
                  },
                },
              } = slice;
              if (sliceType === 'navigation_bar') {
                if (navigationType === 'Link-text') {
                  tItems.push(
                    <Nav.Link
                      key={key(slice)}
                      href={Link.url(navigationLink, linkResolver)}
                    >
                      {navigationText}
                    </Nav.Link>,
                  );
                }
                if (navigationType === 'Dropdown') {
                  const dropdowns = [];
                  lItems.forEach((navItem) => {
                    const {
                      subnavbar_links: subnavbarLinks,
                      subnavbar_title: subnavbarTitle,
                    } = navItem;
                    dropdowns.push(
                      <NavDropdown.Item
                        key={key(navItem)}
                        href={Link.url(subnavbarLinks, linkResolver)}
                      >
                        {subnavbarTitle}
                      </NavDropdown.Item>,
                    );
                  });
                  tItems.push(
                    <NavDropdown
                      title={navigationText}
                      id={key(slice)}
                      key={key(slice)}
                    >
                      {dropdowns}
                    </NavDropdown>,
                  );
                }
                if (navigationType === 'Link-picture') {
                  tItems.push(
                    <Navbar.Brand
                      key={key(slice)}
                      href={Link.url(navigationLink, linkResolver)}
                      className="d-none d-xl-block mr-0"
                    >
                      <picture>
                        <source srcSet={url} />
                        <img
                          width="200"
                          height="60"
                          src={url}
                          alt={alt}
                        />
                      </picture>
                    </Navbar.Brand>,
                  );
                  tBrand.push(
                    <Navbar.Brand
                      key={key(slice)}
                      href={Link.url(navigationLink, linkResolver)}
                    >
                      <picture>
                        <source
                          media="(min-width: 768px)"
                          srcSet={url}
                        />
                        <source
                          media="(max-width: 767px)"
                          srcSet={mUrl}
                        />
                        <img
                          src={url}
                          alt={alt}
                        />
                      </picture>
                    </Navbar.Brand>,
                  );
                }
              }
            });
          } else {
            navBarData.forEach((slice) => {
              const {
                items: lItems,
                slice_type: sliceType, primary: {
                  navigation_type: navigationType,
                  navigation_link: navigationLink,
                  navigation_text: navigationText,
                  navigation_image: {
                    url,
                    alt,
                    mobile: { url: mUrl },
                  },
                },
              } = slice;
              if (sliceType === 'navigation_bar') {
                if (navigationType === 'Link-text') {
                  tItems.push(
                    <Nav.Link
                      key={key(slice)}
                      href={Link.url(navigationLink, linkResolver)}
                    >
                      {navigationText}
                    </Nav.Link>,
                  );
                }
                if (navigationType === 'Dropdown') {
                  const dropdowns = [];
                  lItems.forEach((navItem) => {
                    const {
                      subnavbar_links: subnavbarLinks,
                      subnavbar_title: subnavbarTitle,
                    } = navItem;
                    dropdowns.push(
                      <NavDropdown.Item
                        key={key(navItem)}
                        href={Link.url(subnavbarLinks, linkResolver)}
                      >
                        {subnavbarTitle}
                      </NavDropdown.Item>,
                    );
                  });
                  tItems.push(
                    <NavDropdown
                      title={navigationText}
                      id={key(slice)}
                      key={key(slice)}
                    >
                      {dropdowns}
                    </NavDropdown>,
                  );
                }
                if (navigationType === 'Link-picture') {
                  tBrand.push(
                    <Navbar.Brand
                      key={key(slice)}
                      href={Link.url(navigationLink, linkResolver)}
                    >
                      <picture>
                        <source
                          media="(min-width: 768px)"
                          srcSet={url}
                        />
                        <source
                          media="(max-width: 767px)"
                          srcSet={mUrl}
                        />
                        <img
                          src={url}
                          alt={alt}
                        />
                      </picture>
                    </Navbar.Brand>,
                  );
                }
              }
            });
          }
          setItems(tItems);
          setBrand(tBrand);
        });
    };
    getPrismicData();
    return () => {
      abortController.abort();
    };
  }, [header]);
  if (header === 'normal') {
    return (
      <Navbar
        collapseOnSelect
        expand="xl"
        bg={transparent}
        className={transparent}
        variant="dark"
        id="NavBar"
      >
        <div className="container ">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          {/* possible better way of differentiating mobile/desktop */}
          {window.innerWidth >= 1200 ? '' : brand}
          <Navbar.Collapse id="responsive-navbar-nav">
            {items}
          </Navbar.Collapse>

        </div>
      </Navbar>
    );
  }
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
      className="mobilemenu-submenu-style"
      sticky="top"
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav-mobile" />
      {brand}
      <Navbar.Collapse id="responsive-navbar-nav-mobile">
        {items}
      </Navbar.Collapse>
    </Navbar>
  );
}
export default NavbarPage;
