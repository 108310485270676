import { ScaleLoader } from 'react-spinners';

function Loading() {
  return (
    <div className="text-center mt-5">
      <ScaleLoader
        size={250}
        color="#eaa12e"
      >
        Loading...
      </ScaleLoader>

    </div>
  );
}
export default Loading;
