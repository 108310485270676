import linkResolver from 'prismic-configuration.js';
import { RichText } from 'prismic-reactjs';

function YellowHeader(body) {
  const { slice } = body;
  return (
    <div className="yellowTitle">
      {RichText.render(slice.primary.header_name, linkResolver)}
    </div>
  );
}
export default YellowHeader;
