import Glassdooricon from '../../Assets/icons/glassdoor_transparent copy.png';

function SocialNewsLetter() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-sm-4"
          style={{
            backgroundColor: '#EAA12E',
            color: 'white',
            padding: '1.4rem',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          GET CONNECTED:
          {' '}
          <a
            href="https://www.linkedin.com/company/brinker-international/"
            className="li-ic"
            title="linked-in"
          >
            <i className="fab fa-linkedin-in fa-lg white-text ml-2 mr-4"> </i>
          </a>
          <a
            href="https://www.glassdoor.com/Overview/Working-at-Brinker-International-EI_IE148.11,32.htm"
            className="ins-ic"
            title="glassdoor"
          >
            <img src={Glassdooricon} width="15px" alt="glassdoor icon" />
          </a>
        </div>
        <div
          className="col-sm-8"
          style={{
            backgroundColor: '#992C37',
            color: 'white',
            padding: '1.4rem',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        />
      </div>
    </div>
  );
}
export default SocialNewsLetter;
