import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CircleLoader } from 'react-spinners';
import key from 'weak-key';
import Footer from '../Components/Footer/Footer';
import SocialNewsLetter from '../Components/Footer/SocialNewsLetter';
import NavbarPage from '../Components/Header/NavbarPage';
import Pagination from '../Components/Pagination/CustomPagination';
import YellowHeader from '../Slices/YellowHeader';
import './MediaRoom.css';

const override = css`
  display: block;
  margin: 0 auto;
  text-align: center;
`;

function NewsRelease() {
  // Initialize the state
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [items, setItems] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [matchCount, setMatchCount] = useState([]);
  const [newsDataLists, setNewsDataLists] = useState([]);
  const [page, setPage] = useState(1);
  const [year, setYear] = useState('');
  const [keywords, setKeywords] = useState('');

  const SearchingKeyword = (evt) => {
    setKeywords(evt.target.value);
  };

  const SearchingYear = (evt) => {
    setYear(evt.target.value);
  };

  const getPrismicData = () => {
    const tItems = [];
    fetch('/brinkercom/hotOffThePress/hot-off-the-press')
      .then((res) => res.json())
      .then((data) => {
        const { document: { data: { body } } } = data;
        body.forEach((slice) => {
          const { slice_type: sliceType } = slice;
          if (sliceType === 'yellow_header') {
            tItems.push(<YellowHeader key={key(slice)} slice={slice} />);
          }
        });
      });
    setItems(tItems);
  };

  const getYears = () => {
    const tYearList = [];
    let x = 1;
    for (let n = new Date().getFullYear(); n >= 2009; n--) {
      tYearList.push(
        <option key={x} value={n}>
          {n}
        </option>,
      );
      x += 1;
    }
    setYearList(tYearList);
  };

  const onChangePage = (x, tPage) => {
    document.querySelector('html').scrollTo(0, 0);
    setIsLoaded(true);
    setPage(tPage);
  };
  useEffect(() => {
    getPrismicData();
    getYears();
  }, []);

  useEffect(() => {
    const SearchComponent = () => {
      const offset = page * 10 - 10;

      const jsonData = {
        year,
        keywords,
        offset,
        limit: 10,
      };
      const getParameters = new URLSearchParams(jsonData).toString();
      fetch(`/mediaroom/getFilteredReleaseList?${getParameters}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then(
          (data) => {
            const { matching_count: matchingCount, returned_count: returnedCount } = data;
            const newsArray = [];
            for (let index = 0; index < matchingCount; index++) {
              newsArray.push(index);
            }

            if (returnedCount !== 0) {
              const newsdatalists = data.release.map(
                (newsdatalist) => {
                  const { releaseDate, id, headline } = newsdatalist;
                  const date = new Date(releaseDate);
                  const formattedDate = date.toDateString();
                  return (
                    <li key={key(newsdatalist)} className="wd_item">
                      <div className="wd_item_wrapper">
                        <div className="wd_date">{formattedDate}</div>
                        <div className="wd_title">
                          <NavLink
                            className="nav-link"
                            to={`/media/newsdetails?releaseId=${id}`}
                            rel="noopener noreferrer"
                          >
                            {' '}
                            {headline}
                          </NavLink>
                        </div>
                        <div className="wd_subtitle" />
                        <a
                          className="readMoreSty"
                          href={`/media/newsdetails?releaseId=${id}`}
                          rel="noopener noreferrer"
                        >
                          Read More
                        </a>
                      </div>
                    </li>
                  );
                },
              );
              setIsLoaded(true);
              setNewsDataLists(newsdatalists);
              setIsHidden(false);
              setMatchCount(newsArray);
            } else {
              setIsHidden(true);
              setMatchCount([parseInt(matchingCount, 10)]);
            }
          },
          (e) => {
            setIsLoaded(true);
            setError(e);
          },
        );
    };
    const timer = setTimeout(SearchComponent, 750);
    return () => clearTimeout(timer);
  }, [year, keywords, page]);

  if (error) {
    return (
      <div className="media-page-content company-page-content">
        <div data-sticky-container="" className="sticky-container">
          <div className="media-nav-style nav-style">
            <div
              className="fixed-nav header"
            >
              <NavbarPage />
            </div>
          </div>
        </div>

        <div className="media-newsrelease-container container">
          <h2
            style={{
              color: '#eaa12e',
              textAlign: 'left',
            }}
          >
            HOT OFF THE PRESS
          </h2>
          <div className="row">
            <div className="col-sm">
              <div className="input-group md-form form-sm form-1 pl-0">
                <input
                  className="form-control my-0 py-1 search_input"
                  type="text"
                  placeholder="Search..."
                  aria-label="Search"
                />

                <div
                  className="input-group-prepend"
                  role="searchbox"
                  tabIndex={0}
                >
                  <span
                    className="input-group-text blue lighten-1"
                    id="search-style"
                  >
                    <i className="fa fa-search text-white" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm">
              <div className="input-group md-form form-sm form-1 pl-0">
                <select
                    // ref={(input) => (this.year = input)}
                  className="browser-default custom-select"
                  searchable="Search here.."
                >
                  <option value="">ALL</option>
                  {yearList}
                </select>
                <div className="input-group-prepend">
                  <span
                    className="input-group-text blue lighten-1"
                    id="sort-style"
                  >
                    <i className="fa fa-sort-down text-white" />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="jumbotron">
            <div className="container text-center">
              <h2 style={{ color: 'red' }}>
                {JSON.stringify(error)}
              </h2>
            </div>
          </div>
        </div>
        <div className="text-center mt-10" />

        <SocialNewsLetter />
        <Footer />
      </div>
    );
  } if (!isLoaded) {
    return (
      <div className="jumbotron">
        <div className="container text-center">
          <CircleLoader
            css={override}
            size={150}
            color="#eaa12e"
            loading={isLoaded}
          >
            Loading...
          </CircleLoader>
        </div>
      </div>
    );
  }
  return (
    <div
      className="media-page-content company-page-content"
    >
      <NavbarPage />
      <div className="headerPadding media-newsrelease-container container">
        <div>{items}</div>
        <div className="row">
          <div className="col-sm">
            <div className="input-group md-form form-sm form-1 pl-0">
              <input
                className="form-control my-0 py-1 search_input"
                type="text"
                placeholder="Search..."
                aria-label="Search"
                onChange={SearchingKeyword}
              />

              <div className="input-group-prepend" />
            </div>
          </div>
          <div className="col-sm">
            <div className="input-group md-form form-sm form-1 pl-0">
              <select
                className="browser-default custom-select"
                searchable="Search here.."
                onChange={SearchingYear}
              >
                <option value="">ALL</option>
                {yearList}
              </select>
            </div>
          </div>
        </div>

        <section id="wd_content_section" className="gray">
          <div className="row intro">
            <div className="small-12 columns mt-5" id="summaryContent">
              <div id="wd_printable_content">
                <div className="wd_newsfeed_releases">
                  <ul className="wd_layout-simple wd_item_list">
                    {isHidden ? (
                      <h4
                        className="mt-5"
                        style={{ color: 'red', marginBottom: '3rem' }}
                      >
                        Your Search Returned No Results. Try Again Please!
                      </h4>
                    ) : (
                      newsDataLists
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="text-center mb-5">
        <Pagination
          pageSize={10}
          items={matchCount}
          onChangePage={onChangePage}
          onGetPage={page}
        />
      </div>

      <SocialNewsLetter />
      <Footer />
    </div>
  );

  // }
}
export default NewsRelease;
