import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'prismic-reactjs';
import linkResolver from 'prismic-configuration';
import './Footer.css';
import key from 'weak-key';

function Footer() {
  const [topFooter, setTopFooter] = useState([]);
  const [bottomFooter, setBottomFooter] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    fetch('/brinkercom/footer/footer', {
      signal: abortController.signal,
    })
      .then((res) => res.json())
      .then((data) => {
        const footerData = data.document.data.body;
        const topFooterProp = [];
        const bottomFooterProp = [];
        footerData.forEach((slice) => {
          if (slice.slice_type === 'footer') {
            topFooterProp.push(
              <picture key={key(slice)}>
                <source srcSet={slice.primary.logos.url} />
                <img
                  src={slice.primary.logos.url}
                  alt={slice.primary.logos.alt}
                />
              </picture>,
            );
          }
          if (slice.slice_type === 'footer_menu_links') {
            const menuLinks = [];
            slice.items.forEach((link) => {
              menuLinks.push(
                <div key={key(link)} className="col-md-2 mb-3">
                  <h6 className="text-uppercase font-weight-bold">
                    <a href={Link.url(link.footer_links, linkResolver)}>
                      {link.link_text}
                    </a>
                  </h6>
                </div>,
              );
            });
            topFooterProp.push(
              <div
                key={key(slice)}
                className="row text-center d-flex justify-content-center pt-5 mb-5 mob-mt-mb footerMenu"
              >
                {menuLinks}
              </div>,
            );
          }
          if (slice.slice_type === 'additional_links') {
            const additionalLinks = [];
            let x = 1;
            slice.items.forEach((link, i, array) => {
              additionalLinks.push(
                <a
                  key={x}
                  href={Link.url(link.additional_link_url, linkResolver)}
                >
                  {link.additional_link_text}
                </a>,
              );
              x += 1;
              if (i !== array.length - 1) {
                additionalLinks.push(<span key={x}> | </span>);
                x += 1;
              }
            });
            bottomFooterProp.push(
              <div className="footerAdditionalLinks" key={key(slice)}>
                <hr className="rgba-white-light" />

                <hr
                  className="clearfix d-md-none rgba-white-light brinker-footer-hrline"
                  style={{ margin: '10% 15% 5%' }}
                />

                {additionalLinks}
              </div>,
            );
          }
          if (slice.slice_type === 'copyright') {
            bottomFooterProp.push(
              <div className="copyright" key={key(slice)}>
                {slice.primary.copyright_text}
              </div>,
            );
          }
        });
        setTopFooter(topFooterProp);
        setBottomFooter(bottomFooterProp);
      });
  }, []);

  return (
    <div className="brinker-footer-module">
      <footer className="page-footer font-small indigo">
        <div>
          <Row>
            <Col xs={12} sm={{ span: 10, offset: 1 }}>
              {topFooter}
            </Col>
          </Row>
          <div className="text-center py-3">{bottomFooter}</div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
