import { useState } from 'react';
import 'react-light-accordion/demo/css/index.css';
import HeaderErrImg from '../Assets/images/chilis_photo_collage_r2.jpg';
import HeaderHeroImg from '../Assets/images/shortribfilet.jpg';
import Footer from '../Components/Footer/Footer';
import SocialNewsLetter from '../Components/Footer/SocialNewsLetter';
import NavbarPage from '../Components/Header/NavbarPage';

function NevadaLegalForm(props) {
  const [error] = useState(null);
  const { location: { state: { detail } } } = props;
  const [message] = useState(detail);

  if (error) {
    return (
      <div className="nevada-legal-page-content">
        <div
          data-sticky-container=""
          className="sticky-container"
          style={{ height: '143px' }}
        >
          <div
            id="widemenu"
            className="top-bar sticky is-stuck is-at-top"
            data-sticky="gzuin0-sticky"
            data-resize="widemenu"
            style={{
              marginTop: '1em',
              top: '0px',
              bottom: 'auto',
              left: '0px',
            }}
          >
            <div className="small-12">
              <NavbarPage transparent="chiliBrandCol" />
            </div>
          </div>
        </div>
        <section
          className="chilis hero bg-error-image"
          style={{
            backgroundImage: `url(${HeaderErrImg})`,
          }}
        >
          <div className="overlay" />
          <div className="hero-text" />
        </section>
        <div className="jumbotron">
          <div className="container text-center">
            <h2 style={{ color: 'red' }}>No Data</h2>
          </div>
        </div>

        <SocialNewsLetter />
        <Footer />
      </div>
    );
  }
  return (
    <div className="nevada-legal-page-content">
      <NavbarPage transparent="transparent" />
      <section
        className="brinker-header-module chilis hero"
        style={{
          backgroundImage: `url(${HeaderHeroImg})`,
        }}
      >
        <div className="overlay" />
        <div
          className="hero-text"
        >
          <div className="grid special">
            <h1 className="header">NEVADA DATA PRIVACY STATUTE</h1>
          </div>
        </div>
      </section>

      <div className="brinker-wrapper-module mt-5 mb-5">
        <div className="brinker-legal-module" id="NevadaSB2020-Module">
          <div className="terms container">
            <div className="row nevada-marg" style={{ display: 'block' }}>
              <h5>{message}</h5>
              <strong
                style={{
                  color: '#EE2D24',
                  fontSize: '21px',
                }}
              >
                Right to Opt Out of Sale of Personal Information for Nevada
                consumers
              </strong>
            </div>

            <div className="row nevada-marg">
              Brinker does not sell your personal information. But we do
              follow the law! And Nevada law says that we need to give a
              Nevada consumer a method to submit a request to us not to sell
              his or her personal information, whether collected previously
              or in the future. So please feel free to complete the form
              below. Please note that we are making this available to all of
              Brinker’s U.S. consumers.
            </div>
          </div>
        </div>
      </div>
      <SocialNewsLetter />
      <Footer />
    </div>
  );
}
export default NevadaLegalForm;
