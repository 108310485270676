import { useEffect, useState } from 'react';

function ApiDisplay() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const abortController = new AbortController();
    fetch(window.location.pathname + window.location.search, { signal: abortController.signal })
      .then((res) => res.json())
      .then((api) => {
        setData(api);
      });
  }, []);

  return (
    <div>
      <pre>{JSON.stringify(data, null, 2) }</pre>
    </div>
  );
}
export default ApiDisplay;
