import PropTypes from 'prop-types';
import { Component } from 'react';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.number),
  onChangePage: PropTypes.func.isRequired,
  initialPage: PropTypes.number,
  pageSize: PropTypes.number,
};

const defaultProps = {
  items: {},
  initialPage: 1,
  pageSize: PropTypes.number,
};

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  componentDidMount() {
    // set page if items array isn't empty
    const { initialPage } = this.props;
    this.setPage(initialPage, true);
  }

  componentDidUpdate(prevProps) {
    // reset page if items array has changed
    const { items: { length }, initialPage } = this.props;
    const { items: { length: pLength } } = prevProps;
    if (length !== pLength) {
      this.setPage(initialPage);
    }
  }

  setPage(page, startup) {
    const { items } = this.props;
    let { pager } = this.state;
    // get new pager object for specified page
    pager = this.getPager(page);
    const { startIndex, endIndex } = pager;
    // get new page of items from items array
    const pageOfItems = items.slice(startIndex, endIndex + 1);

    // update state
    this.setState({ pager });

    // call change page function in parent component
    if (!startup) {
      const { onChangePage } = this.props;
      onChangePage(pageOfItems, page);
    }
  }

  getPager(currentPage) {
    const { items: { length: totalItems }, pageSize } = this.props;
    // default to first page
    const selectedPage = currentPage || 1;

    // default page size is 10
    const itemsPerPage = pageSize || 10;

    // calculate total pages
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    let startPage;
    let endPage;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else if (selectedPage <= 6) {
      // more than 10 total pages so calculate start and end pages
      startPage = 1;
      endPage = 10;
    } else if (selectedPage + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = selectedPage - 5;
      endPage = selectedPage + 4;
    }

    // calculate start and end item indexes
    const startIndex = (selectedPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = [...Array(endPage + 1 - startPage).keys()].map(
      (i) => startPage + i,
    );

    // return object with all pager properties required by the view
    return {
      totalItems,
      selectedPage,
      itemsPerPage,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    };
  }

  render() {
    const { pager } = this.state;

    if (!pager.pages || pager.pages.length <= 1) {
      // don't display pager if there is only 1 page
      return null;
    }

    return (
      <ul className="pagination">
        <li className={pager.selectedPage === 1 ? 'disabled' : ''}>
          <a href="#0" onClick={() => this.setPage(1)}>
            First
          </a>
        </li>
        <li className={pager.selectedPage === 1 ? 'disabled' : ''}>
          <a href="#0" onClick={() => this.setPage(pager.selectedPage - 1)}>
            Previous
          </a>
        </li>
        {pager.pages.map((page) => (
          <li
            key={page}
            className={pager.selectedPage === page ? 'active' : ''}
          >
            <a href="#0" onClick={() => this.setPage(page)}>
              {page}
            </a>
          </li>
        ))}
        <li
          className={pager.selectedPage === pager.totalPages ? 'disabled' : ''}
        >
          <a href="#0" onClick={() => this.setPage(pager.selectedPage + 1)}>
            Next
          </a>
        </li>
        <li
          className={pager.selectedPage === pager.totalPages ? 'disabled' : ''}
        >
          <a href="#0" onClick={() => this.setPage(pager.totalPages)}>
            Last
          </a>
        </li>
      </ul>
    );
  }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;
export default Pagination;
