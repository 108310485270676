import parse from 'html-react-parser';
import { useCallback, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { DotLoader } from 'react-spinners';
import Footer from '../Components/Footer/Footer';
import SocialNewsLetter from '../Components/Footer/SocialNewsLetter';
import NavbarPage from '../Components/Header/NavbarPage';
import './NewsDetails.css';

const getUrlParameter = (sParam) => {
  const sPageURL = decodeURIComponent(window.location.search.substring(1));

  const sParameterName = sPageURL.split('&')[0].split('=');
  if (sParameterName[0] === sParam) {
    return sParameterName[1] === undefined ? true : sParameterName[1];
  }
  return false;
};

function NewsDetails() {
  // Initialize the state
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [newsDetailedObjData, setnewsDetailedObjData] = useState({});
  const [open, setOpen] = useState(false);
  const [newsdetailedBodyObjData, setNewsdetailedBodyObjData] = useState({});
  const [newsdetailassetsmainsection, setNewsdetailassetsmainsection] = useState({});
  const [imgAssetsHtml1, setImgAssetsHtml1] = useState('');
  // Fetch the list on first mount
  const toggleBox = useCallback(() => {
    setOpen(!open);
  }, [open]);
  useEffect(() => {
    // Retrieves the list of items from the Express app
    const getNewsDetailsList = (releaseId) => {
      const jsonData = {
        releaseId,
      };
      const getParameters = new URLSearchParams(jsonData).toString();

      fetch(`/mediaroom/getRelease?${getParameters}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then(
          (data) => {
            if (data.body) {
              setnewsDetailedObjData(data);
              setNewsdetailedBodyObjData(data.body.replace(/<img[^>]*>/g, ''));

              const isImgAsset = data.body.match(/src="(.*?)"/g);
              const isImgAssetFilter = [];
              isImgAsset.forEach((url) => {
                if (!url.includes('rt.prnewswire.com')) {
                  isImgAssetFilter.push(url);
                }
              });
              const isVideoAsset = data.body.match(/<object(.*?)<\/object>/g);
              if (isImgAssetFilter !== null && isImgAssetFilter.length > 0) {
                setNewsdetailassetsmainsection(
                  <div className="news-asset-wrapper">
                    <div className="news-assset-container">
                      <div className="tab-conteiner">
                        <ul className="news-tabs">
                          {isVideoAsset !== null && isVideoAsset.length > 0 && (
                          <li
                            className="list-news-tab videos closed"
                          >
                            <a
                              href="#0"
                              onClick={toggleBox}
                            >
                              <span className="tab_close_icon" />
                              <span
                                className="fab fa-video-camera"
                                style={{ fontSize: '1.2em' }}
                              />
                              <span className="wd_asset_label">Video </span>
                              <span className="asset_count">
                                {' '}
                                {`(${isVideoAsset.length})`}
                                {' '}
                              </span>
                            </a>
                          </li>
                          )}

                          <li
                            className="list-news-tab images closed"
                          >

                            <a href="#0" onClick={toggleBox}>
                              <span className="tab_close_icon" />
                              <span className="wd_asset_label">Photos</span>
                              <span className="asset_count">
                                {' '}
                                {`(${isImgAssetFilter.length})`}
                              </span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>,
                );

                let c = 0;
                let imgAssetsHtml = '';
                for (let i = 0; i < isImgAssetFilter.length; i++) {
                  const imageSourceURL = isImgAssetFilter[i]
                    .replace('"', '')
                    .replace('"', '')
                    .replace('src=', '');
                  if (c !== isImgAssetFilter.length) {
                    imgAssetsHtml += `<div class= 'imgcontainer'><a href='${
                      imageSourceURL
                    }' target='_blank'><img class ='image' src=${
                      imageSourceURL
                    } border='0' alt=''/> `
                    + '<div class=\'imgoverlay\'>'
                    + ' <div href=\'#\' class=\'icon\' title=\'User Profile\'> <i class=\'fa fa-download\'> </i> </div> </div> </a></div>';
                  }
                  c += 1;
                }
                setImgAssetsHtml1(imgAssetsHtml);
                setIsLoaded(true);
              } else {
                setNewsdetailassetsmainsection(
                  <ul className="news-tabs">
                    <li
                      className="list-news-tab videos closed"
                      style={{ display: 'none' }}
                    >
                      <a href="#0">
                        <span className="tab_close_icon" />
                        <span
                          className="asset_icon fa fa-video-camera"
                          style={{ fontSize: '1.2em' }}
                        />
                        <span className="wd_asset_label">Video</span>
                        <span className="asset_count">(2)</span>
                      </a>
                    </li>
                    <li
                      className="list-news-tab images closed"
                      style={{ display: 'none' }}
                    >
                      <a href="#0">
                        <span className="tab_close_icon" />
                        <span
                          className="asset_icon fa fa-photo"
                          style={{ fontSize: '1.2em' }}
                        />
                        <span className="wd_asset_label">Photos</span>
                        <span className="asset_count" />
                      </a>
                    </li>
                  </ul>,
                );
              }
            }
            setIsLoaded(true);
          },
          (e) => {
            setIsLoaded(true);
            setError(e);
          },
        );
    };
    const tempID = getUrlParameter('releaseId');
    if (
      tempID !== false
      && typeof tempID !== 'undefined'
    ) {
      const releaseId = tempID;
      getNewsDetailsList(releaseId);
    }
    // need to fix to stop fetching every time picture is requested
  }, [toggleBox]);

  if (error) {
    return (
      <div>
        {' '}
        <div className="media-page-content company-page-content">
          <div className="media-nav-style nav-style">
            <div>
              <NavbarPage transparent="transparent" />
            </div>
          </div>
          <div className="jumbotron">
            <div className="container text-center">
              <h2 style={{ color: 'red', marginTop: '30px' }}>
                Oops, something went wrong!
              </h2>
            </div>
          </div>

          <SocialNewsLetter />
          <Footer />
        </div>
      </div>
    );
  } if (!isLoaded) {
    return (
      <DotLoader
        size={150}
        color="#eaa12e"
        className="loadingCenter"
      />
    );
  }
  return (
    <div className="media-page-content company-page-content">
      <NavbarPage transparent="transparent" />

      <div className="jumbotron">
        <div className="container">
          <section id="wd_content_section" className="gray">
            <div className="row intro">
              <div className="small-12 columns">
                <div id="wd_printable_content">
                  <div className="wd_newsfeed_releases-detail">
                    <div
                      className="wd_title wd_language_left"
                      id="newstitle"
                    >
                      <div id="wd_printable_content">
                        <NavLink
                          className="nav-link gobackbreadcrumb"
                          to="/media/hot-off-the-press"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-chevron-left gobackbreadcrumbhover">
                                &nbsp; GO BACK
                          </i>
                        </NavLink>
                      </div>
                      {newsDetailedObjData.headline}
                    </div>
                    <div id="wd_subtitle">
                      <div className="wd_subtitle wd_language_left">
                        {' '}
                        {newsDetailedObjData.subheadline}
                      </div>
                    </div>

                    {newsdetailassetsmainsection}
                    {open && (
                      <div
                        className="asset-gallery gallery-records"
                        style={{ display: 'block' }}
                      >
                        <div className="gallery-details">
                          <div id="show_img">
                            {parse(imgAssetsHtml1)}
                          </div>
                        </div>
                        <div
                          className="close-gallery-details"
                          align="right"
                        >
                          <a href="#0">
                            Close
                            {' '}
                            <i className="fa fa-caret-square-up  mr-4" />
                          </a>
                        </div>
                      </div>
                    )}
                    {open && (
                      <div className="video-asset-gallery gallery-records">
                        <div className="news-video">
                          <div id="listing-videos" />
                        </div>
                        <div
                          className="close-gallery-details"
                          align="right"
                        >
                          <a href="#0">
                            Close
                            {' '}
                            <i
                              className="fa fa-caret-square-up"
                              aria-hidden="true"
                            />
                          </a>
                        </div>
                      </div>
                    )}

                    <div
                      className="wd_body wd_news_body"
                      wd_resize="formatNews"
                    >
                      <div id="news_article">
                        {parse(
                          newsdetailedBodyObjData,
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="gray">
            <div className="row">
              <div className="small-12 columns">
                <div id="social-media" />
              </div>
            </div>
          </section>
        </div>
      </div>
      <SocialNewsLetter />
      <Footer />
    </div>
  );
}
export default NewsDetails;
